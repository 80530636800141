import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => (
  <Layout>
    <SEO title="Contact Us" />
    <div className="pure-u-1-1">
      <h1>Contact Us</h1>
      <p>Get in touch with us below, or via <a href="mailto:contact@kickcode.com">contact@kickcode.com</a></p>

      <form class="contact" method="POST" action="http://kickcode-mailer.herokuapp.com/send">
        <input type="hidden" name="destination" id="destination" value="4ebfb7d7fcde9dbc79e2a696d1aae1aa9ae81496" />

        <p>
          <input type="text" name="name" id="name" placeholder="Enter name" />
        </p>

        <p>
          <input type="text" name="email" id="email" placeholder="Enter email" />
        </p>

        <p>
          <textarea type="text" name="details" id="details" rows="10" cols="50" placeholder="Enter message"></textarea>
        </p>

        <input type="submit" value="Send" class="button uppercut" />
      </form>
    </div>
  </Layout>
)

export default Contact
